import { AnyAction } from 'redux'
import {
    ACTION_DASHBOARD_SEARCH    
} from './dashboard-actions'
import { makeReducer } from '../../utils'
import { ActionReducer, Reducers } from '../../utils/make-reducer'
export type DashboardState = {
    searchTerm?: string
}

type DashboardReducer = ActionReducer<DashboardState, AnyAction>

const initialState: DashboardState = {
    searchTerm: undefined,
}

const searchTerm: DashboardReducer = (state, action) => ({
    ...state,
    searchTerm: action.data,
})

const reducers: Reducers<DashboardState> = {
    [ACTION_DASHBOARD_SEARCH]: searchTerm,
}

export const dashboardReducer = makeReducer<DashboardState>(reducers, initialState)
