import { FormControl, MenuItem, Select } from '@mui/material';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { changeLanguage } from 'i18next';
import { getLang } from './language-picker-helper';
import { Flag } from './flags/flags';
import { KeyboardArrowDown } from '@mui/icons-material';
const useStyles = makeStyles((theme: Theme) => ({
  languageLine: {
    display: 'flex',
    alignItems: 'center',
  },
  languagePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '32px',
  },
  languageLabel: {
    paddingLeft: '10px',
  },
  select: {
    '& .MuiSvgIcon-root': {
      fill: '#fff',
      width: '16px',
    },
  },
}));
export const LanguagePicker: React.FC = () => {
  const [currentLang, setCurrentLang] = useState(getLang());
  const styles = useStyles();
  return (
    <div className={styles.languagePickerContainer}>
      <FormControl variant='standard'>
        <Select
          className={styles.select}
          value={currentLang}
          disableUnderline={true}
          IconComponent={KeyboardArrowDown}
          renderValue={(value) => {
            return <Flag country={value} />;
          }}
          variant={'standard'}
          onChange={(event) => {
            changeLanguage(event.target.value);
            setCurrentLang(event.target.value);
          }}
          displayEmpty
          name='language'>
          <MenuItem key={'nb'} value='nb'>
            <div className={styles.languageLine}>
              <Flag country={'nb'} />
              <span className={styles.languageLabel}>Norsk</span>
            </div>
          </MenuItem>
          <MenuItem key={'en'} value='en'>
            <div className={styles.languageLine}>
              <Flag country={'en'} />
              <span className={styles.languageLabel}>English</span>
            </div>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
