import { AnyAction } from 'redux'
import { LoginRequest } from '../../models/login-request'

export const ACTION_DASHBOARD_SEARCH = 'ACTION_DASHBOARD_SEARCH'
export const ACTION_AUTH_INITIALIZED = 'ACTION_AUTH_INITIALIZED'
export const ACTION_AUTH_LOGIN = 'ACTION_AUTH_LOGIN'
export const ACTION_AUTH_LOGIN_SUCCESS = 'ACTION_AUTH_LOGIN_SUCCESS'
export const ACTION_AUTH_LOGIN_ERROR = 'ACTION_AUTH_LOGIN_ERROR'
export const ACTION_AUTH_LOGOUT = 'ACTION_AUTH_LOGOUT'

export const actionDashboardSearch = (term: string): AnyAction => ({
    type: ACTION_DASHBOARD_SEARCH,
    term,
})

export const actionAuthInitialized = (): AnyAction => ({
    type: ACTION_AUTH_INITIALIZED,
})

export const actionAuthLogin = (request: LoginRequest): AnyAction => ({
    type: ACTION_AUTH_LOGIN,
    request,
})

export const actionAuthLoginSuccess = (data: any): AnyAction => ({
    type: ACTION_AUTH_LOGIN_SUCCESS,
    data,
})

export const actionAuthLoginError = (): AnyAction => ({
    type: ACTION_AUTH_LOGIN_ERROR,
})

export const actionAuthLogout = (): AnyAction => ({
    type: ACTION_AUTH_LOGOUT,
})
